<template>
  <div class="cooperate">
    <div class="top-banner">
      <div class="title container">
        <h1>招商合作</h1>
        <h2>INVESTMENT COOPERATION</h2>
      </div>
    </div>
    <div class="wrapper container space">
      <div class="hidden-xs">
        <img src="../assets/cooperate/coo_1.png" alt="">
        <img src="../assets/cooperate/coo_2.png" alt="">
        <img src="../assets/cooperate/coo_3.png" alt="">
        <img src="../assets/cooperate/coo_4.png" alt="">
        <img src="../assets/cooperate/coo_5.png" alt="">  
        <img src="../assets/cooperate/coo_6.png" alt=""> 
        <img class="img1" src="../assets/cooperate/coo_7.png" alt="">   
      </div>
      <div class="visible-xs">
        <img src="../assets/cooperate/mobile/coo_1.png" alt="">
        <img src="../assets/cooperate/mobile/coo_2.png" alt="">
        <img src="../assets/cooperate/mobile/coo_3.png" alt="">
        <img src="../assets/cooperate/mobile/coo_4.png" alt="">
        <img src="../assets/cooperate/mobile/coo_5.png" alt="">
        <img src="../assets/cooperate/mobile/coo_6.png" alt="">
        <img src="../assets/cooperate/mobile/coo_7.png" alt="">      
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cooperate',
}
</script>

<style lang="scss" scoped>
.cooperate {
  .wrapper {
    text-align: center;
    img {
      width: 100%;
      margin-bottom: 60px;
      box-shadow:0px 2px 40px 0px rgba(0,0,0,0.2);
    }
    img:nth-child(2n+1){
      position: relative;
      left: -30px;
    }
    img:nth-child(2n){
      position: relative;
      right: -30px;
    }
  }
  .img1 {
    margin-top: 60px;
  }
}
@media (max-width: 1024px){
  .cooperate {
    .wrapper {
      img:nth-child(2n+1){
        left: 0;
      }
      img:nth-child(2n){
        right: 0;
      }
    }
  }
}
@media (max-width: 768px){
  .cooperate .wrapper {
    img { 
      margin-bottom: 20px;
      display: block;
    }
  }
}
</style>